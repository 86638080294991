import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import classes from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author {
            name
          }
        }
      }
    }
  `)
  return (
    <footer className={classes.footer}>
      <p>
        Created by <strong>{data.site.siteMetadata.author.name}</strong>{" "}
        Copyright 2020{" "}
      </p>
      <ul className={classes.Ul}>
        <li>
          <a href="skype:live:mortuz?chat">
            <img src="/img/icons/skype-logo.svg" alt="skype" />
          </a>
        </li>
        <li>
          <a
            href="https://twitter.com/mortuz_a"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/img/icons/twitter-logo.svg" alt="twitter" />
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/mortuz/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/img/icons/linkedin-logo.svg" alt="linkedin" />
          </a>
        </li>
        <li>
          <a href="mailto:mortuz@idevia.in">
            <img src="/img/icons/email.svg" alt="email" />
          </a>
        </li>
      </ul>
    </footer>
  )
}

export default Footer

import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import classes from './header.module.scss';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  
  return (
    <nav className={classes.header}>
      <Link to="/" className={classes.title}>
        <h1>{data.site.siteMetadata.title}<span className={classes.dot}>.</span></h1>
      </Link>
      <ul className={classes.navList}>
        <li>
          <Link to="/" className={classes.navItem} activeClassName={classes.activeNavItem}>Home</Link>
        </li>
        <li>
          <Link to="/blog" className={classes.navItem} activeClassName={classes.activeNavItem}>Blog</Link>
        </li>
        <li>
          <Link to="/about" className={classes.navItem} activeClassName={classes.activeNavItem}>About</Link>
        </li>
        {/* <li>
          <Link to="/contact" className={classes.navItem} activeClassName={classes.activeNavItem}>Contact</Link>
        </li> */}
      </ul>
    </nav>
  )
}

export default Header
import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';

import '../styles/index.scss';
import classes from './layout.module.scss';

const Layout = (props) => (
  <div className={classes.container}>
    <div className={classes.content}>
      <Header />
      <div>{props.children}</div>
    </div>
    <Footer />
  </div>
)

export default Layout;